@use 'mixins';

.TagIntroBlock {
    position: relative;
    margin-bottom: var(--hugePadding);
    padding: var(--largePadding);
    background-color: rgb(var(--invertedBackground));
    z-index: 0;
    white-space: pre-line;
    color: rgb(var(--invertedTextColor));
    border-radius: var(--standardRadius);

    :global(.theme-br) & {
        padding: var(--largePadding) 0 0 0;
        background-color: initial;
    }

    .partner {
        padding: var(--tinyPadding);
        margin-bottom: calc(var(--standardPadding) + var(--smallPadding));

        > div {
            padding: 0;
        }

        &:after {
            border-top: 1px solid rgb(var(--secondaryTextColor));
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            margin-top: calc(var(--smallPadding) + 1px);
        }
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        position: relative;

        hr {
            margin-left: calc(var(--largePadding) * -1);
            width: calc(100% + 2 * var(--largePadding));
            background-color: rgb(var(--sectionBorder));
            z-index: 50;
        }

        .header-container {
            width: 100%;

            :global(.theme-br) & {
                margin-top: calc(var(--right-shape-height) * 0.99);
                padding: 0 var(--largePadding) var(--largePadding) var(--largePadding);
                background: rgb(var(--invertedBackground));
            }
        }

        :global(.theme-vi) & {
            flex-direction: row-reverse;
        }

        // Bright heading shape left
        :global(.theme-br) & {
            &::before {
                display: block;
                position: absolute;
                top: calc(var(--right-shape-height) * -1);
                right: 0;
                width: var(--right-shape-width);
                height: var(--right-shape-height);
                margin: auto 0 auto auto;
                background: rgb(var(--mainColor));
                z-index: 0;
                content: '';
                transform: translateY(100%);
                clip-path: var(--right-shape-clip);
            }

            &::after {
                display: block;
                position: absolute;
                bottom: 100%;
                left: 0;
                width: var(--left-shape-width);
                height: var(--left-shape-height);
                background: rgb(var(--invertedBackground));
                z-index: 1;
                content: '';
                transform: translateY(calc(-100% + var(--left-shape-height) + var(--right-shape-height)));
                clip-path: var(--left-shape-clip);
            }
        }
    }

    .thumbnail-container {
        display: flex;
        z-index: 100;

        :global(.theme-vi) & {
            height: 3em;
            margin-right: var(--standardPadding);
        }

        img {
            width: 7.5em;
            height: 7.5em;
            padding: 1px;
            margin-left: var(--hugePadding);
            border: var(--tinyPadding) solid rgb(var(--sectionBorder));
            background-color: rgb(var(--background));

            :global(.theme-vi) & {
                width: 3em;
                height: 3em;
                margin-left: 0;
                padding: 0;
                border: none;
                border-radius: var(--standardRadius);
            }
        }

        .team-name-tag {
            position: absolute;
            bottom: var(--standardPadding);
            right: calc(var(--largePadding) + var(--smallPadding));
            padding: var(--standardRadius) var(--smallPadding);
            background-color: rgb(var(--mainColor));
            font-weight: 700;
            letter-spacing: 0.4px;
            font-size: 0.75em;
            line-height: 1.5em;
            text-transform: uppercase;
            color: rgb(var(--invertedTextColor));
            border-radius: var(--standardRadius);
        }
    }

    .header-tophalf {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;

        .thumbnail-container {
            display: none !important;
        }

        .inner-tag-container {
            :global(.theme-br) & {
                display: none;
            }
        }
    }

    h1 {
        width: fit-content;
        padding: var(--tinyPadding) 0 var(--smallPadding) 0;
        line-height: 1.1em;

        :global(.theme-br) & {
            display: block;
            width: 100%;
            padding: 0;
            background: rgb(var(--invertedBackground));
            line-height: initial;
            z-index: 1;
            box-sizing: border-box;
            text-decoration: underline;
            color: rgb(var(--invertedTextColor));
            text-underline-offset: 0.2em;
            text-decoration-thickness: var(--largeRadius);
            text-decoration-color: rgb(var(--mainColor));

            // Bright second underline right
            &::after {
                display: block;
                height: 100%;
                margin: auto 0 0;
                content: '';
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            }
        }

        :global(.theme-vi) & {
            padding: 0;
            margin-top: calc(-1 * var(--tinyPadding));
            line-height: 1em;
            font-size: 1.75em;
        }

        i {
            font-style: normal;
            font-weight: 400;
            opacity: 0.6;

            :global(.theme-br) & {
                // Negative margin for occasional split between words
                font-weight: inherit;
                margin-right: -1px;
                opacity: initial;
            }
        }
    }

    .description {
        @extend %paragraph-large;

        margin-bottom: 0;
        opacity: 0.8;
        color: inherit;
        padding-top: 1em;

        :global(.theme-br) & {
            margin-top: var(--standardPadding);
        }
    }

    .trackimage-container {
        position: absolute;
        top: var(--tinyPadding);
        right: 0;
        height: 100%;
        width: fit-content;
        z-index: 1;

        img {
            float: right;
            height: 100%;
            max-height: 100%;
            width: auto;
        }
    }

    &::after,
    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 180px;
        background-repeat: no-repeat;
        z-index: -1;

        :global(.theme-vi) & {
            // vi blocks will always have a dark background
            display: none;
        }
    }

    .background-image {
        background-color: rgb(var(--invertedBackground));
        object-fit: cover;

        :global(.theme-br) & {
            background-color: initial;
        }
    }

    &::after {
        background-image: linear-gradient(
                to left,
                rgba(var(--invertedBackground), 0) 28%,
                rgb(var(--invertedBackground)) 85%
            ),
            linear-gradient(
                191deg,
                rgba(var(--invertedBackground), 0) 12%,
                rgb(var(--invertedBackground)) 85%
            ),
            linear-gradient(
                180deg,
                rgba(var(--invertedBackground), 0) 35%,
                rgb(var(--invertedBackground)) 92%
            );
        content: '';
        backdrop-filter: blur(2px);

        :global(.theme-br) & {
            display: none;
        }
    }

    .driver-rank {
        display: inline-block;
        width: 100%;
        margin: var(--standardPadding) 0;

        p {
            display: inherit;
            margin-left: var(--standardPadding);
            font-weight: 700;
            letter-spacing: 0.4px;
        }

        :global(.counter) {
            width: 1.35em;
            background-color: rgba(255, 255, 255, 0.2);
            font-size: 1.125em;
            color: rgb(var(--invertedTextColor));
            border-radius: 1px;
        }

        .go-to-driver {
            color: rgb(var(--background));
        }

        .team-drivers {
            a {
                text-decoration: none;
            }

            .driver-name {
                padding: 1px var(--smallPadding);
                margin-right: 0;
                margin-left: var(--tinyPadding);
                background-color: rgba(255, 255, 255, 0.1);
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.75em;
                text-transform: uppercase;
                border-radius: var(--standardRadius);
            }
        }

        .drivers {
            display: flex;
            float: right;

            .driver-name {
                padding: var(--tinyPadding) var(--smallPadding);
                margin-left: 0;
                margin-right: var(--tinyPadding);
                background-color: rgb(var(--mainColor));
                color: rgb(var(--invertedTextColor));
                font-size: 0.75em;
                text-transform: uppercase;
                border-radius: var(--standardRadius);
            }
        }

        hr {
            margin-top: var(--standardPadding);
            margin-bottom: var(--standardPadding);
        }

        /* stylelint-disable-next-line selector-class-pattern */
        :global(.Icon.arrowsRight-icon) {
            float: right;
            width: 1.5em;
            height: 1.5em;
            color: rgb(var(--background));
        }
    }

    &.no-tag-outro {
        padding-bottom: var(--smallPadding);
    }

    &.team-type .thumbnail-container,
    &.grand-prix-type .thumbnail-container {
        position: absolute;
        display: flex;
        top: var(--largePadding);
        right: var(--largePadding);

        img {
            margin: 0;
            border: none;
            background-color: transparent;
        }
    }

    &.driver-type .thumbnail-container {
        position: absolute;
        display: flex;
        bottom: 0;
        right: calc(var(--standardPadding) * -1.4);

        img {
            height: 11.25em;
            width: auto;
            margin: -45.5px var(--standardPadding) 0;
            background-color: transparent;
            border: none;
        }
    }

    &.author-type {
        .thumbnail-container {
            display: flex;
            position: absolute;
            right: 0;
            bottom: 0;
            height: calc(100% - var(--largePadding));
            aspect-ratio: 1;
            object-fit: contain;
            object-position: bottom;

            img {
                margin: 0;
                background-color: transparent;
                border: none;
            }

            .team-name-tag {
                bottom: 0.75em;
            }
        }

        header {
            .description {
                display: block;
            }

            .thumbnail-container {
                .team-name-tag {
                    white-space: nowrap;
                }
            }
        }

        .header-tophalf {
            .thumbnail-container {
                display: none;
            }
        }

        .tag-body .description {
            display: block !important;
        }
    }

    &.team-type .thumbnail-container img {
        margin-top: calc(var(--standardPadding) * -1.4);
        margin-right: var(--standardPadding);
        width: auto;
        height: 5.25em;
    }

    &.common-type {
        header .description {
            display: block;
        }

        .read-more {
            display: none;
        }

        &.is-in-list {
            .description p {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .read-more {
                display: block;
                padding: var(--largePadding);
                padding-left: 0;
                border: none;
                text-transform: uppercase;

                &:hover {
                    color: rgb(var(--mainColor));
                    background-color: transparent;

                    :global(.theme-vp) &,
                    :global(.theme-vpbe) &,
                    :global(.theme-vn) & {
                        color: rgb(var(--thirdColor));
                        background-color: transparent;
                    }
                }

                :global(.Icon) {
                    fill: rgb(var(--mainColor));
                }

                :global(.theme-vp) & {
                    :global(.Icon) {
                        fill: rgb(var(--thirdColor));
                    }
                }
            }
        }

        .tag-body .description {
            display: none;
        }
    }

    &.is-in-list {
        margin-bottom: 0;
    }

    &.team-type.is-mini-view,
    &.driver-type.is-mini-view,
    &.grand-prix-type.is-mini-view {
        margin-bottom: 0;
        padding: var(--largePadding) var(--standardPadding) 0 var(--standardPadding);

        .inner-tag-container {
            padding: 1px var(--smallPadding);

            h3 {
                display: block;
                font-size: 0.875em;
                line-height: 1.5em;
                letter-spacing: 0.4px;
            }
        }

        .thumbnail-container {
            right: var(--standardPadding);

            img {
                width: auto;
                max-width: 14em;
                height: 5.625em;
                margin-top: calc(var(--hugePadding) * -1);
                margin-right: 0;
            }
        }

        header h1 {
            font-size: 1.75em;
        }
    }

    &.team-type.is-mini-view .thumbnail-container img {
        height: 3.625em;
        width: auto;
        max-width: 10em;
        margin-top: calc(var(--hugePadding) * -0.4);
    }

    &.grand-prix-type .thumbnail-container img {
        margin-top: calc(var(--standardPadding) * -1);
        width: 17.5em;
        height: 8.75em;
    }

    &.grand-prix-type.is-mini-view .thumbnail-container img {
        width: 11.25em;
        margin-top: calc(var(--largePadding) * -1);
    }

    &.driver-type.is-mini-view {
        .thumbnail-container {
            display: none;
        }

        .header-container .header-tophalf .thumbnail-container {
            display: flex !important;
            right: 0;

            img {
                padding: 0;
                display: block;
                height: 6.0625em;
            }
        }
    }

    .scroll-to-tag-outro {
        display: block;
        margin: auto;
        width: 4.625em;
        height: 2.5em;
        color: rgb(var(--invertedTextColor));
        background-color: rgba(var(--invertedBackground), 0.1);
        border: none;
        border-radius: 1.25em;

        :global(.Icon) {
            display: contents;
            font-size: 1.5em;
            color: rgb(var(--invertedTextColor));
        }
    }

    .tag-body {
        margin-top: var(--largePadding);
        background-color: rgb(var(--invertedBackground));

        .description {
            padding-bottom: var(--largePadding);
        }

        h3 {
            margin: 0;
            margin-top: var(--standardPadding);
        }

        dl {
            display: block;
            margin: 0;
            margin: var(--largePadding) 0;
            border-radius: 1px;
            text-transform: capitalize;

            i {
                font-style: normal;
                text-transform: lowercase;
            }

            dt {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
            }

            dd {
                font-weight: 700;
            }

            /* stylelint-disable-next-line selector-class-pattern */
            :global(.DetailsBlock_odd) {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    &.team-type,
    &.driver-type,
    &.grand-prix-type {
        header {
            display: block;

            h1 {
                padding-top: var(--standardPadding);
                border-bottom: 3px solid rgb(var(--mainColor));
            }
        }
    }

    :global(.theme-gp) & {
        border-top: 3px solid rgb(var(--mainColor));

        .inner-tag-container {
            display: block;
            width: fit-content;
            margin-top: calc((var(--largePadding) * -1) - 3px);
            padding: var(--smallPadding) var(--standardPadding);
            background-color: rgb(var(--mainColor));
            border-radius: var(--standardRadius);

            h3 {
                font-weight: 700;
                font-size: 1.125em;
                line-height: 1em;
                text-transform: uppercase;
                letter-spacing: 0.6px;
                color: inherit;
            }
        }
    }

    :global(.theme-vi) & {
        padding: var(--mediumPadding) var(--standardPadding);
        background-color: rgb(var(--lighterInvertedBackground));

        &.no-space-below {
            margin: 0;
            border-radius: var(--standardRadius) var(--standardRadius) 0 0;
        }

        .inner-tag-container h3 {
            margin-top: var(--tinyPadding);
            color: rgb(var(--lighterTextColor));
            text-transform: capitalize;
            font-weight: 400;
            font-size: 1.125em;
            line-height: 1em;
        }

        hr {
            display: none;
        }
    }

    @include mixins.responsive(m, below) {
        margin-bottom: var(--standardPadding);

        header .inner-tag-container {
            padding: var(--smallPadding) var(--smallPadding) var(--tinyPadding) var(--smallPadding);
            font-weight: 700;
        }

        &.common-type {
            header .description {
                display: none;

                :global(.theme-br) & {
                    display: block;
                }
            }

            .tag-body {
                :global(.theme-br) & {
                    display: none;
                }

                .description {
                    display: block;
                }
            }
        }

        :first-of-type > &.driver-type {
            margin-top: var(--standardPadding);
        }

        &.author-type {
            header {
                .description {
                    display: block;
                }
            }

            .thumbnail-container {
                display: flex;
                position: absolute;
                inset: calc(var(--largePadding) * -1) 0 var(--tinyPadding) auto;
                transform: translateY(-1px);

                .team-name-tag {
                    bottom: -0.2em;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .team-name-tag {
                right: 0;
                bottom: 0;
                padding: var(--standardRadius) var(--smallPadding);
                font-size: 0.75em;
                font-weight: 700;
                transform: translateY(calc(var(--largePadding) + var(--standardPadding)));
            }

            .tag-body .description {
                margin-top: initial;
            }

            .tag-body {
                :global(.theme-br) & {
                    margin-top: 0;
                    padding-right: var(--standardPadding);
                    padding-left: var(--standardPadding);
                }
            }
        }

        &.author-type .header-tophalf {
            .thumbnail-container {
                display: none;
            }
        }

        &.driver-type {
            .header-tophalf {
                .thumbnail-container {
                    display: flex !important;
                }
            }

            .thumbnail-container {
                display: none;
            }

            .thumbnail-container img {
                height: 6.68em;
                width: auto;
                padding: 0;
            }

            .team-name-tag {
                top: 83px;
                bottom: unset;
                white-space: nowrap;
            }
        }

        &.no-space-below {
            :global(.theme-vi) & {
                border-radius: 0;
            }
        }
    }

    @include mixins.responsive(m, above) {
        &.author-type {
            .thumbnail-container {
                display: flex;
                position: absolute;
                top: initial;
                right: 0;
                height: calc(100% + var(--largePadding));

                // Fix for the top border
                :global(.theme-gp) & {
                    height: calc(100% - 1px + var(--largePadding));
                    border-bottom: 2px solid transparent;
                }

                .team-name-tag {
                    right: 0;
                }
            }

            .thumbnail-container img {
                width: 100%;
                height: 100%;
            }

            .tag-body .description {
                margin-top: initial;
            }

            .tag-body {
                margin-top: 0;

                :global(.theme-br) & {
                    padding-right: var(--largePadding);
                    padding-left: var(--largePadding);
                }
            }
        }
    }

    @include mixins.responsive(l, below) {
        :global(.theme-br) & {
            header {
                .header-container {
                    padding: 0 var(--standardPadding) var(--largePadding) var(--standardPadding);
                }
            }
        }
    }

    @include mixins.responsive(l, above) {
        &.author-type {
            :global(.theme-gp) & {
                header {
                    padding-bottom: var(--largePadding);

                    .description {
                        display: block;
                    }
                }

                .tag-body {
                    margin-top: initial;
                }

                .thumbnail-container {
                    display: flex;
                    position: absolute;
                    bottom: var(--largePadding);
                    height: calc(100% - 1px);
                }
            }
        }
    }
}
